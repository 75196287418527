import { motion } from "framer-motion"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { iconVariants } from "./variants"

function MenuButton() {
  return (
    <Wrapper initial="ini" animate="in" exit="out" variants={iconVariants}>
      <NavLink to="/menu">...</NavLink>
    </Wrapper>
  )
}

export default MenuButton

const Wrapper = styled(motion.div)`
  position: absolute;
  top: 1rem;
  right: 3rem;
  cursor: pointer;
  font-size: 3rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
