/* variants of animations for framer-motion */
/* ---------------------------------------------------- */
/* Page transtion for pages - Home, About, Contact */
export const pageTransition = {
  ini: {
    opacity: 0,
    y: "-30%",
  },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
      when: "beforeChildren",
      staggerChildren: 0.1,
      /* staggerDirection: -1, */
    },
  },
  out: {
    opacity: 0,
    /* y: "30%", */
    transition: {
      duration: 0.1,
      when: "afterChildren",
      staggerChildren: 0.05,
      /* staggerDirection: -1, */
    },
  },
}

export const pageItemVariants = {
  ini: {
    opacity: 0,
    y: -30,
  },
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: 30,
  },
}
/* -------------------------------------- */
/* For animation of menu button - rotate */
export const iconVariants = {
  ini: {
    rotate: 420,
    opacity: 0,
  },
  in: {
    rotate: 0,
    opacity: 1,
  },
  out: {
    rotate: 420,
    opacity: 0,
  },
}

/* ----------------------------------- */
/* Animation of menu, gradually appear*/
export const menuVariants = {
  ini: {
    opacity: 0,
    y: "-30%",
  },
  in: {
    opacity: 1,
    y: "0",
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      /* staggerDirection: -1, */
    },
  },
  out: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.05,
      /* staggerDirection: -1, */
    },
  },
}

export const linkVariants = {
  ini: {
    opacity: 0,
    x: -30,
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: -30,
  },
}
/* --------------------------------------- */
