import { motion } from "framer-motion"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { menuVariants, linkVariants } from "./variants"
/* Menu page*/

const Menu = () => {
  return (
    <Wrapper initial="ini" animate="in" exit="out" variants={menuVariants}>
      <motion.div
        variants={linkVariants}
        style={{ fontFamily: "qanelas", marginBottom: "2rem" }}
      >
        meníčko:
      </motion.div>
      <motion.span variants={linkVariants} whileHover={{ scale: 1.2 }}>
        <NavLink to="/">dóm</NavLink>
      </motion.span>
      <motion.span variants={linkVariants} whileHover={{ scale: 1.2 }}>
        <NavLink to="/about">co to je?</NavLink>
      </motion.span>
      <motion.span variants={linkVariants} whileHover={{ scale: 1.2 }}>
        <NavLink to="/contact">kontakt</NavLink>
      </motion.span>
    </Wrapper>
  )
}

export default Menu

const Wrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
