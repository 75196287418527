import { motion } from "framer-motion"
import styled from "styled-components"
import { pageTransition, pageItemVariants } from "./variants"

/* Contact info page */

const Contact = () => {
  return (
    <Wrapper initial="ini" animate="in" exit="out" variants={pageTransition}>
      {/*  <div>nultyvzorek.</div> */}
      <motion.div variants={pageItemVariants} style={{ textAlign: "center" }}>
        jak se nám ozvat?
      </motion.div>
      <ContactInfo variants={pageItemVariants}>
        <div>nultyvzorek@email.cz</div>

        {/* <div>fb: Nultý vzorek</div> */}
        {/* <div className="small">
          nebo tady na webu přes messenger, osmý řádek vlevo dole
        </div> */}
      </ContactInfo>
    </Wrapper>
  )
}

export default Contact

const Wrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ContactInfo = styled(motion.div)`
  max-width: 60%;
  margin: 2vh 0 5vh 0;
  font-size: 1.6rem;
  font-family: qanelas;
  letter-spacing: 0.05rem;
  line-height: 1.35;
  text-align: center;
  display: flex;
  justify-content: center;
  > .small {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  @media (max-width: 400px) {
    max-width: 80%;
    font-size: 1.15rem;
    padding: 2rem 0;
    > .small {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }
`
