import { AnimatePresence } from "framer-motion"
import { Switch, Route, useLocation } from "react-router-dom"
import About from "./components/About"
import Home from "./components/Home"
import Contact from "./components/Contact"
import Menu from "./components/Menu"
import MenuButton from "./components/MenuButton"
import styled from "styled-components"
import bg from "./images/bg.webp"
/* import MessengerCustomerChat from "react-messenger-customer-chat" */

const App = () => {
  const location = useLocation()
  return (
    <div className="App" style={{ backgroundColor: "#fff" }}>
      <Main>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/">
              <Home />
              <MenuButton />
            </Route>
            <Route path="/about">
              <About />
              <MenuButton />
            </Route>
            <Route path="/contact">
              <Contact />
              <MenuButton />
              {/*     <MessengerCustomerChat
                pageId=""
                appId=""
              /> */}
            </Route>
            <Route path="/menu">
              <Menu />
            </Route>
          </Switch>
        </AnimatePresence>
      </Main>
    </div>
  )
}

export default App

const Main = styled.div`
  font-family: qanelasBold;
  font-size: 2.3rem;
  font-weight: bold;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10vh 0 0 0;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  a {
    color: #000;
    text-decoration: none;
  }
`
