import { motion } from "framer-motion"
import { pageTransition, pageItemVariants } from "./variants"

/* Home page */

const Home = () => {
  return (
    <motion.div initial="ini" animate="in" exit="out" variants={pageTransition}>
      <motion.div variants={pageItemVariants}>nultyvzorek.</motion.div>
    </motion.div>
  )
}

export default Home
