import { motion } from "framer-motion"
import styled, { keyframes } from "styled-components"
import { pageTransition, pageItemVariants } from "./variants"
import obr1 from "../images/n1.jpg"
import obr2 from "../images/n2.jpg"
import obr3 from "../images/n3.jpg"
import obr4 from "../images/n4.jpg"
import obr5 from "../images/n5.jpg"
import obr6 from "../images/n6.jpg"
import obr7 from "../images/n7.jpg"
import obr8 from "../images/n8.jpg"

const images = [obr1, obr2, obr3, obr4, obr5, obr6, obr7, obr8]
/* About band page */

const About = () => {
  return (
    <Wrapper initial="ini" animate="in" exit="out" variants={pageTransition}>
      <motion.div variants={pageItemVariants}>cotoje?</motion.div>
      <AboutText variants={pageItemVariants}>
        Nultý vzorek je hudební seskupení z Březolup, které vám zahraje k tanci
        a občas i k poslechu. Hrajeme převzaté písničky ve stylu kredance
        alternative folk & pank & rep & rock & roll. Naše kapela se seznámila na
        slivovicovém večírku a věděli sme za chvilku, že tohle naše spojení se
        okamžitě promění v osudové spojení a nekonečné umění. Hrajeme na
        svatbách, oslavách či jiných slavnostech a jednou do roka máme koncert v
        naší rodné střediskové domovině.
      </AboutText>
      <Marquee variants={pageItemVariants}>
        <MarqueeInner aria-hidden="true">
          {images.map((image, index) => (
            <img key={index} src={image} alt={"asd"} />
          ))}
          {images.map((image, index) => (
            <img key={index} src={image} alt={"asd"} />
          ))}
          {images.map((image, index) => (
            <img key={index} src={image} alt={"asd"} />
          ))}
          {images.map((image, index) => (
            <img key={index} src={image} alt={"asd"} />
          ))}
        </MarqueeInner>
      </Marquee>
    </Wrapper>
  )
}

export default About

const Wrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
  @media (max-width: 400px) {
    padding-top: 3rem;
  }
`

const AboutText = styled(motion.div)`
  width: 60%;
  margin: 2vh 0 5vh 0;
  font-size: 21px;
  font-family: qanelas;
  letter-spacing: 0.05rem;
  line-height: 1.4;
  padding: 1rem 0;
  @media (max-width: 400px) {
    min-width: 80%;
    font-size: 18px;
    padding: 2rem 0;
  }
`

const Marquee = styled(motion.div)`
  position: relative;
  overflow: hidden;
  width: 100vw;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  > div > img {
    height: 50px;
    width: auto;
    margin: 20px;
  }
  @media (max-width: 400px) {
    padding-bottom: 2rem;
  }
`

const marqueeKeyframes = keyframes`
    0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
`

const MarqueeInner = styled(motion.div)`
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: ${marqueeKeyframes} 10s linear infinite;
  animation-play-state: running;
`
